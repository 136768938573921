/*
 * @Descripttion:
 * @FilePath: /sxexpress/src/utils/compressImage.js
 * @Author: 张兴业
 * @Date: 2021-03-30 21:14:14
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-03-30 21:22:25
 */

const compressImage = (
  file,
  maxSize = Math.pow(1024, 2),
  qualitys = 0.92,
  success,
  error
) => {
  console.log(file.size, maxSize, parseFloat(file.size / maxSize));
  // 图片小于maxSize不压缩
  if (file.size <= maxSize) {
    return success(file);
  }

  const ratio = parseFloat(file.size / maxSize);

  var max = 2;

  if (ratio >= 1 && ratio < 2) {
    max = 1.2;
  } else if (ratio >= 2 && ratio <= 5) {
    max = 1.5;
  } else if (ratio > 5 && ratio <= 8) {
    max = 2;
  } else if (ratio > 8 && ratio <= 12) {
    max = 2.5;
  } else if (ratio > 12 && ratio <= 16) {
    max = 3;
  } else {
    max = 3.5;
  }

  // const size = file.size;

  const name = file.name; //文件名
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = e => {
    const src = e.target.result;

    const img = new Image();
    img.src = src;
    img.onload = () => {
      const w = img.width / max;
      const h = img.height / max;
      const quality = qualitys; // 默认图片质量为0.92
      //生成canvas
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      // 创建属性节点
      const anw = document.createAttribute("width");
      anw.nodeValue = w;
      const anh = document.createAttribute("height");
      anh.nodeValue = h;
      canvas.setAttributeNode(anw);
      canvas.setAttributeNode(anh);

      //铺底色 PNG转JPEG时透明区域会变黑色
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, w, h);

      ctx.drawImage(img, 0, 0, w, h);
      // quality值越小，所绘制出的图像越模糊
      const base64 = canvas.toDataURL("image/jpeg", quality); //图片格式jpeg或webp可以选0-1质量区间

      // 返回base64转blob的值
      console.log(
        `原图${(src.length / 1024).toFixed(2)}kb`,
        `新图${(base64.length / 1024).toFixed(2)}kb`
      );
      //去掉url的头，并转换为byte
      const bytes = window.atob(base64.split(",")[1]);
      //处理异常,将ascii码小于0的转换为大于0
      const ab = new ArrayBuffer(bytes.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      var file = new Blob([ab], { type: "image/jpeg" });
      file.name = name;
      // file.type = "."+file.type.split("/")[1];
      success(file);
    };
    img.onerror = e => {
      error(e);
    };
  };
  reader.onerror = e => {
    error(e);
  };
};

export default compressImage;
