<!--
 * @Descripttion: 
 * @FilePath: /sxexpress/src/views/idcard/index.vue
 * @Author: 张兴业
 * @Date: 2021-03-30 16:03:24
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-10-18 15:23:20
-->
<template>
  <div>
    <!-- <form
      name="form1"
      method="post"
      action="./NetWork2.aspx"
      id="form1"
      enctype="multipart/form-data"
    > -->
    <div>
      <input
        type="hidden"
        name="__VIEWSTATE"
        id="__VIEWSTATE"
        value="/wEPDwUKMTY2Njk2MjEyOQ9kFgICAw8WAh4HZW5jdHlwZQUTbXVsdGlwYXJ0L2Zvcm0tZGF0YWRkhIbzgAKovj6sL+aFR9H2PG/XsZNbdWStE0/8yx5e11M="
      />
    </div>

    <div>
      <input
        type="hidden"
        name="__VIEWSTATEGENERATOR"
        id="__VIEWSTATEGENERATOR"
        value="C4485115"
      />
      <input
        type="hidden"
        name="__EVENTVALIDATION"
        id="__EVENTVALIDATION"
        value="/wEdAAkAqIh3N2uTcWrtkcfSGM8H7rwqpCcT3w/qcVBLROI5R3HfFRc7Z/lNlMxggTd/c1fVOY2ZHysY0w+xZENbF8glhcqReEO3tUFrFE2z0XfRzKM6CWabl8ZKTlSSkWx8rh4ZQFO6JsyFOomXzm7xQBx1lIjHWl87SetQ9CoXE8ixhTzmltaUM7aEAN+g9cP/m13lGbW07/azE0l6Zqx9L0IIvN71i+pgsWf/nif5xL8JlA=="
      />
    </div>
    <div class="wrap" @click="hidenAsideRight">
      <div class="">
        <div class="header">
          <div class="top tr hidden-phone">
            <div class="w1180">
              <div class="fl">
                您好，欢迎来到三象速递官网
              </div>
              <div class="fr">
                www.sxexpress.com.au
              </div>
              <div class="cl"></div>
            </div>
          </div>

          <div class="w1180 clearfix">
            <h2 class="fl pt10 pb10 logo">
              <a href="/"> <img src="@/assets/images/logo.png"/></a>
            </h2>
            <div class="fr pt10 pb10 phone-nav hidden-pc">
              <i class="el-icon-s-unfold" @click.stop="changeMenu"></i>
            </div>
            <div class="fr mt20 ml30 hidden-phone" style="position: relative;">
              <ul>
                <li class="fr ml20">
                  <a
                    href="javascript:;"
                    onmouseover="document.getElementById('weixinDIV').style.display = 'block';"
                    onmouseout="document.getElementById('weixinDIV').style.display = 'none';"
                  >
                    <img
                      src="@/assets/images/top-wx.png"
                      alt="与微信客服交流"
                    />
                  </a>
                </li>
                <li class="fr">
                  <a
                    href="http://wpa.qq.com/msgrd?v=3&amp;uin=3376684454&amp;site=qq&amp;menu=yes"
                  >
                    <img src="@/assets/images/top-qq.png" alt="与QQ客服交流" />
                  </a>
                </li>
              </ul>
              <div
                id="weixinDIV"
                onmouseover="this.style.display = 'block';"
                onmouseout="this.style.display = 'none';"
                style="position: absolute; right: 0px; top: 45px; display: none; z-index: 10000;"
              >
                <img src="@/assets/images/wx.jpg" style="width: 140px;" />
              </div>
            </div>
            <div class="nav fr hidden-phone">
              <ul>
                <li><a href="/">首页</a></li>
                <!-- <li><a href="news.aspx">活动公告</a></li> -->
                <li><a href="/service">特色服务</a></li>
                <li><a href="/about">关于三象</a></li>
                <li><a href="/contact">联系三象</a></li>
              </ul>
            </div>
          </div>
        </div>

        <!--START: Banner-->
        <!--START: Content -->
        <div class="inside fw">
          <div class="inside_banner network_banner hidden-phone">
            <div class="title f14 w1180">
              <div style="margin-left: 25px">
                <span class="line"></span>
                <h2>身份证上传</h2>
              </div>
            </div>
          </div>
          <div class="w1180 clearfix ">
            <div class="f14 idcard-all">
              <div class="idcard-main">
                <div class="upcard-logo hidden-phone clearfix">
                  <div class="fl">
                    <img
                      src="@/assets/images/upcard-icon2.png"
                      style="width: 40px;"
                    />
                  </div>
                  <div class="fl upcard-title">中华人民共和国海关总署</div>
                </div>
                <div class="up_div1_main hidden-phone">
                  <div class="up_div1_main_title">
                    《中华人民共和国海关对进出境快件监管办法》
                  </div>
                  <div class="up_div1_main_title2">
                    海关总署令第147号
                  </div>
                  <div class="up_div1_main_font">
                    根据中国海关总署修订后的《中华人民共和国海关对进出境快件监管办法》第二十二条规定，入境到中国大陆的个人包裹经海关查验需向海关提供收件人身份证件
                    (彩色) 等相关信息。
                  </div>
                  <div class="up_div1_main_font2">
                    前往
                    <a href="#">中华人民共和国海关总署官方网站</a>
                    查看原文 》
                  </div>
                </div>
                <div class="upcard-div2">
                  <div class="upcard-div2-main">
                    <div class="upcard-div2-title">
                      1. 上传证件图片 <span>(请确保证件图片清晰可见)</span>
                    </div>
                    <div class="clearfix upcard-list">
                      <!-- <el-upload
                          class="upload-demo"
                          drag
                          action="https://jsonplaceholder.typicode.com/posts/"
                          multiple
                        > -->

                      <!-- 正身份证上传、 -->
                      <div class="fl">
                        <el-upload
                          class="uplode"
                          action="https://jsonplaceholder.typicode.com/posts/"
                          :show-file-list="false"
                          :on-success="handleAvatarSuccess"
                          :before-upload="beforeUpload_z"
                          accept="image/*"
                        >
                          <div
                            id="IDPic1"
                            style="padding-bottom: 5px; text-align: center;"
                          >
                            <a style="display: block;">
                              <img
                                v-if="postData.image1 == ''"
                                id="ImgCnoZm"
                                src="@/assets/images/upcard-btn3.jpg"
                                style="border-width:0px;width: 324px; height: 187px;"
                              />

                              <img
                                v-else
                                :src="postData.image1"
                                style="border-width:0px;width: 324px; height: 187px;"
                              />
                            </a>
                          </div>
                        </el-upload>
                        <a
                          id="UpBtn1"
                          class="hidden-phone"
                          style="margin: 0px; padding: 0px; line-height: 10px"
                        >
                          <img
                            src="@/assets/images/upload-btn1.png"
                            style="display: block; margin: 0 auto;"
                          />
                        </a>
                      </div>

                      <div class="fl col-6">
                        <el-upload
                          class="uplode"
                          action="https://jsonplaceholder.typicode.com/posts/"
                          :show-file-list="false"
                          :on-success="handleAvatarSuccess"
                          :before-upload="beforeUpload_f"
                          accept="image/*"
                        >
                          <!-- 反面身份证上传 -->
                          <div
                            class="id_card"
                            id="IDPic2"
                            style="padding-bottom: 5px; text-align: center;"
                          >
                            <a style="display: block;">
                              <img
                                v-if="postData.image2 == ''"
                                id="ImgCnoFm"
                                src="@/assets/images/upcard-btn4.jpg"
                                style="border-width:0px;width: 324px; height: 187px;"
                              />
                              <img
                                v-else
                                :src="postData.image2"
                                style="border-width:0px;width: 324px; height: 187px;"
                              />
                            </a>
                          </div>
                        </el-upload>
                        <a
                          id="UpBtn2"
                          class="hidden-phone"
                          style="margin: 0px; padding: 0px; line-height: 10px"
                        >
                          <img
                            src="@/assets/images/upload-btn2.png"
                            style="display: block; margin: 0 auto;"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="upcard-div2-main">
                    <div class="upcard-div2-title">
                      2. 信息核对
                      <span>(信息已智能识别，核对无误后提交)</span>
                    </div>
                    <div class="clearfix upcard-input-form">
                      <div class="fl col-3">
                        <div class="mr5">
                          <el-input
                            v-model="postData.mobile"
                            type="text"
                            id="txtTel"
                            placeholder="收件人手机号码"
                            maxlength="11"
                          />
                        </div>
                      </div>
                      <div class="fl col-2">
                        <div class="mr5">
                          <el-input
                            v-model="postData.name"
                            id="txtName"
                            placeholder="收件人姓名"
                            maxlength="10"
                          />
                        </div>
                      </div>
                      <div class="fl col-4">
                        <div class="mr5">
                          <el-input
                            v-model="postData.idCardNo"
                            type="text"
                            id="txtCNo"
                            placeholder="身份证号"
                            maxlength="18"
                          />
                        </div>
                      </div>
                      <div class="fl col-3">
                        <input
                          type="submit"
                          name="btnExist"
                          value="验证身份证是否已经上传"
                          @click="yanzheng"
                          id="btnExist"
                          class="btn yzbtn"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="upcard-div2" style="text-align: center;">
                  <input
                    type="button"
                    name="btnSubmit"
                    value="核对无误，确认提交"
                    @click="tijiao"
                    id="btnSubmit"
                    style="font-family: 'microsoft yahei'; font-size: 18px; padding: 10px 20px; background: #ef8202; color: #fff; border: none; outline: none; cursor: pointer;"
                  />
                </div>
                <div class="upcard-div2 hidden-phone" style="margin-top: 80px;">
                  <div class="fl" style="width: 290px; font-size: 16px;">
                    <p class="mb20"><b>三象速递服务承诺：</b></p>
                    <p class="mb15">1、所有证件信息均进行SSL加密存储</p>
                    <p class="mb15">2、保密隐私，证件图片自动添加水印</p>
                    <p>3、清关查验后系统自动删除证件信息</p>
                  </div>
                  <div class="fr">
                    <div style="margin-bottom: 10px;">证件图片示例：</div>
                    <div>
                      <img src="@/assets/images/id.png" style="width: 80%;" />
                    </div>
                  </div>
                  <div class="clear"></div>
                </div>
              </div>
            </div>
          </div>
          <!--END: Content -->

          <div class="foot">
            <div class="copyright w1180 tc">
              <p>
                Copyright © 2017-2020 三象速递
                &nbsp;&nbsp;&nbsp;&nbsp;联系电话：+61 1300965539
                &nbsp;&nbsp;&nbsp;&nbsp;地址：Po Box 6635, Point Cook, VIC
                3030&nbsp;&nbsp;&nbsp;&nbsp;<a
                  href="http://www.kuaidi100.com/"
                  target="_blank"
                  >快递查询</a
                >
              </p>
            </div>
          </div>

          <!-- 代码部分end -->
        </div>
      </div>
    </div>

    <!-- aside right -->
    <aside
      class="ui-aside asideRight w23"
      :style="
        showAsideRight
          ? 'top: 0px; bottom: 0px; right: -1px; width: 120px;'
          : 'top: 0px; bottom: 0px; right: -4rem; width: 0'
      "
    >
      <ul>
        <li><a href="/">首页</a></li>
        <!-- <li><a href="news.aspx">活动公告</a></li> -->
        <li><a href="/service">特色服务</a></li>
        <li><a href="/about">关于三象</a></li>
        <li><a href="/contact">联系三象</a></li>
      </ul>
    </aside>
    <!-- </form> -->
  </div>
</template>
<script>
import request from "@/utils/request";
import compress from "@/utils/compressImage";
// import { v1 as uuidv1 } from "uuid"
import dayjs from "dayjs";

export default {
  data() {
    return {
      lodingData: {},
      postData: {
        image1: "",
        image2: "",
        name: "",
        mobile: "",
        idCardNo: "",
        frontUrl: "",
        backUrl: ""
      },
      allow: ["png", "jpg", "jpeg"],
      showAsideRight: false
    };
  },
  components: {},
  methods: {
    yanzheng() {
      let postData = {
        name: this.postData.name,
        mobile: this.postData.mobile
      };
      if (postData.name == "" && postData.mobile == "") {
        // alert("收件人手机号，收件人姓名不能都为空");
        this.$alert("收件人手机号，收件人姓名不能都为空", "提示", {
          confirmButtonText: "确定"
        });
        return;
      }
      this.openFullScreen2();
      request("/api/fadmin/daemon/logistics/checkIdCardExist", {
        method: "POST",
        data: postData
      })
        .then(res => {
          // alert(res.message);
          this.$alert(res.message, "提示", {
            confirmButtonText: "确定"
          });
          this.lodingData.close();
          console.log(res);
        })
        .catch(err => {
          // alert(err.message);
          this.$alert(err.message, "提示", {
            confirmButtonText: "确定"
          });
          this.lodingData.close();
          console.log(err);
        });
      console.log(this.postData);
    },
    tijiao() {
      let postData = this.postData;
      if (postData.image1 == "" || postData.image2 == "") {
        // alert("身份证照片不能为空");
        this.$alert("身份证照片不能为空", "提示", {
          confirmButtonText: "确定"
        });
        return;
      }
      if (
        postData.name == "" ||
        postData.mobile == "" ||
        postData.idCardNo == ""
      ) {
        // alert("收件人手机号，收件人姓名，身份证号都不可为空");
        this.$alert("收件人手机号，收件人姓名，身份证号都不可为空", "提示", {
          confirmButtonText: "确定"
        });
        return;
      }
      this.openFullScreen2();
      request("/api/fadmin/daemon/logistics/idUpload", {
        method: "POST",
        data: postData
      })
        .then(res => {
          // alert(res);
          if (res) {
            if (res.code === 1) {
              this.$alert("上传成功！", "提示", {
                confirmButtonText: "确定",
                callback: () => {
                  this.postData = {
                    name: "",
                    mobile: "",
                    idCardNo: "",
                    image1: "",
                    image2: ""
                  };
                }
              });
            } else {
              this.$alert(res.message, "提示", {
                confirmButtonText: "确定"
              });
            }
          } else {
            this.$alert("请求失败，请稍后再试！", "提示", {
              confirmButtonText: "确定"
            });
          }
          this.lodingData.close();
          console.log(res);
        })
        .catch(err => {
          // alert(err.message);
          this.$alert(err.message, "提示", {
            confirmButtonText: "确定"
          });
          this.lodingData.close();
          console.log(err);
        });
      console.log(this.postData);
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    openFullScreen2() {
      this.lodingData = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
    },
    beforeUpload_z(file) {
      console.log(file);
      const maxSize = 500 * 1024;
      compress(file, maxSize, 0.92, f => {
        console.log("当前正面大小", f.size / 1024 + "kb");
        if (file.size <= maxSize) {
          const type = f.name
            .split(".")
            [f.name.split(".").length - 1].toLowerCase();
          const allow = this.allow;
          const isJPG = allow.indexOf(type) > -1;
          const isLt5M = f.size / 1024 < 500;
          if (!isJPG) {
            this.$message.error("只支持上传png, jpg, jpeg后缀的图片");
            return false;
          }
          console.log("===", file);
          if (!isLt5M) {
            this.$message.error("上传文件大小不能超过500kb!");
            return false;
          }
          // this.openFullScreen2();

          let files = new window.File([file], file.name, {
            type: file.type
          });

          const params = {
            kind: "eweIdCard",
            sourceId: dayjs().format("YYYY年MM月DD日")
          };

          const formData = new FormData();
          formData.append("file", files);

          request("/api/daemon/files/upload", {
            method: "POST",
            params,
            data: formData,
            requestType: "form"
          })
            .then(res => {
              if (res && res.code === 1) {
                this.postData.frontUrl = res.data.linkUrl;

                var imgFile = new FileReader();
                imgFile.readAsDataURL(file);

                imgFile.onload = d => {
                  this.postData.image1 = d.currentTarget.result;
                  request("/api/daemon/logistics/OCRFrontCustomer", {
                    method: "POST",
                    data: {
                      pic: d.currentTarget.result,
                      username: "sx",
                      password: "88d422c9-652f-464b-9586-9876a5fdb793"
                    }
                  })
                    .then(res1 => {
                      console.log(res1);
                      if (
                        res1 &&
                        res1.code === 1 &&
                        res1.data &&
                        res1.data.length
                      ) {
                        this.postData.name = res1.data[0].Name;
                        this.postData.idCardNo = res1.data[0].IdCardNo;
                      }
                    })
                    .catch(() => {});
                };
                // request("/api/daemon/identity/parseIdCardPicture", {
                //   method: "POST",
                //   data: {
                //     frontUrl: res.data.linkUrl
                //   }
                // })
                //   .then(res1 => {
                //     if (res1 && res1.code === 1 && res1.data) {
                //       this.postData.name = res1.data.idcardName;
                //       this.postData.idCardNo = res1.data.idcardNo;
                //     }
                //   })
                //   .catch(() => {});
              }
            })
            .catch(() => {});
        } else {
          this.beforeUpload_z(f);
        }
      });

      return false;
    },
    beforeUpload_f(file) {
      const maxSize = 500 * 1024;
      compress(file, maxSize, 0.92, f => {
        console.log("当前反面大小", f.size / 1024 + "kb");
        if (f.size <= maxSize) {
          const type = f.name
            .split(".")
            [f.name.split(".").length - 1].toLowerCase();
          const allow = this.allow;
          const isJPG = allow.indexOf(type) > -1;
          const isLt5M = f.size / 1024 < 500;
          if (!isJPG) {
            this.$message.error("只支持上传png, jpg, jpeg后缀的图片");
            return false;
          }
          // console.log(f);
          if (!isLt5M) {
            this.$message.error("上传文件大小不能超过500Kb!");
            return false;
          }
          // this.openFullScreen2();

          let files = new window.File([file], file.name, {
            type: file.type
          });

          const params = {
            kind: "eweIdCard",
            sourceId: dayjs().format("YYYY年MM月DD日")
          };

          const formData = new FormData();
          formData.append("file", files);

          request("/api/daemon/files/upload", {
            method: "POST",
            params,
            data: formData,
            requestType: "form"
          })
            .then(res => {
              if (res && res.code === 1) {
                this.postData.backUrl = res.data.linkUrl;

                var imgFile = new FileReader();
                imgFile.readAsDataURL(f);

                imgFile.onload = d => {
                  this.postData.image2 = d.currentTarget.result;
                };
              }
            })
            .catch(() => {});
        } else {
          this.beforeUpload_f(f);
        }
      });

      return false;
    },
    changeMenu() {
      //移动端右侧滑出
      this.showAsideRight = true;
    },
    hidenAsideRight() {
      this.showAsideRight = false;
    }
  }
};
</script>
<style scoped>
.yzbtn {
  position: relative;
  top: 2px;
  line-height: 24px;
}
</style>
